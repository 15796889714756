export default [  
  {
    title: 'Ayuda',
    icon: 'HelpCircleIcon',
    route: 'help-center',  
    resource: 'Inversionista',
    action: 'all',   
  },
  {
    title: 'Ayuda',
    icon: 'HelpCircleIcon',
    route: 'help-center',  
    resource: 'Productor',
    action: 'all',   
  },
  {
    title: 'Mis Proyectos',
    icon: 'BriefcaseIcon',
    route: 'my-projects',  
    resource: 'Productor',
    action: 'all',   
  },
  {
    title: 'Mis Inversiones',
    icon: 'BarChartIcon',
    route: 'my-investments',  
    resource: 'Inversionista',
    action: 'all',   
  },
  {
    title: 'Información Productor',
    icon: 'UserIcon',
    route: 'edit-producer-profile',  
    resource: 'Productor',
    action: 'all',   
  },
  {
    title: 'Información NeoGanadero',
    icon: 'UserIcon',
    route: 'edit-investor-profile',  
    resource: 'Inversionista',
    action: 'all',   
  },
  {
    title: 'Información Usuario',
    icon: 'UserIcon',
    route: 'edit-cattle-seller-profile',  
    resource: 'UsuarioSubasta',
    action: 'all',   
  },
  {
    title: 'Buscar Proyectos',
    icon: 'SearchIcon',
    route: 'search-projects',  
    resource: 'Inversionista',
    action: 'all',   
  },
  // {
  //   title: 'Ofertas de Ganado',
  //   icon: 'GridIcon',
  //   route: 'cattle-offerings',  
  //   resource: 'Inversionista',
  //   action: 'all',   
  // },
  {
    title: 'Mis Ofertadas de Ganado',
    icon: 'GridIcon',
    route: 'my-cattle-offerings',  
    resource: 'UsuarioSubasta',
    action: 'all',   
  },
  {
    title: 'Ofertas de Ganado',
    icon: 'GridIcon',
    route: 'cattle-offerings',  
    resource: 'UsuarioSubasta',
    action: 'all',   
  },
  {
    title: 'Tablero de Control',
    icon: 'ClipboardIcon',
    route: 'control-board',  
    resource: 'Operador',
    action: 'all',   
  },
  {
    title: 'Proyectos',
    icon: 'CheckSquareIcon',
    route: 'projects-to-aprove',  
    resource: 'Operador',
    action: 'all',
  },
  {
    title: 'Inversiones',
    icon: 'FolderIcon',
    route: 'investments-to-aprove',  
    resource: 'Admin',
    action: 'all',   
  }, 
  {
    title: 'Usuarios',
    icon: 'UsersIcon',
    route: 'users-management',  
    resource: 'Admin',
    action: 'all',   
  },
  {
    title: 'Transferencias',
    icon: 'DownloadIcon',
    route: 'money-requests-to-approve',  
    resource: 'Admin',
    action: 'all',   
  },  
  {
    title: 'Métricas',
    icon: 'BarChart2Icon',
    route: 'get-metrics',  
    resource: 'Admin',
    action: 'all',   
  },
  {
    title: 'Ofertas de Ganado',
    icon: 'GridIcon',
    route: 'cattle-offerings-management',  
    resource: 'Admin',
    action: 'all',   
  },
  {
    title: 'Mis fincas de carbono',
    icon: 'ImageIcon',
    route: 'my-carbon-farms',  
    resource: 'ProductorCarbono',
    action: 'all',   
  },
  {
    title: 'Calendario Documentos',
    icon: 'CheckSquareIcon',
    route: 'calendar-documents-carbon-farm',  
    resource: 'ProductorCarbono',
    action: 'all',   
  },
  {
    title: 'Productores',
    icon: 'UsersIcon',
    route: 'producers-management',  
    resource: 'AdminCarbono',
    action: 'all',   
  },
  {
    title: 'Fincas',
    icon: 'ImageIcon',
    route: 'farms-management',  
    resource: 'AdminCarbono',
    action: 'all',   
  },
  {
    title: 'Capturas y emisiones',
    icon: 'CloudSnowIcon',
    route: 'register-metrics',  
    resource: 'AdminCarbono',
    action: 'all',   
  },
  {
    title: 'Ventas Créditos',
    icon: 'TrendingUpIcon',
    route: 'carbon-sales',  
    resource: 'AdminCarbono',
    action: 'all',   
  },
  {
    title: 'Checklist',
    icon: 'CheckSquareIcon',
    route: 'farms-checklist',  
    resource: 'AdminCarbono',
    action: 'all',   
  },
  {
    title: 'Lista Eventos',
    icon: 'CheckSquareIcon',
    route: 'events-list',  
    resource: 'AdminCarbono',
    action: 'all',   
  },
  {
    title: 'Conf. Documentos',
    icon: 'CheckSquareIcon',
    route: 'calendar-documents-administration',  
    resource: 'AdminCarbono',
    action: 'all',
  },
  {
    title: 'Gestión Documentos',
    icon: 'CheckSquareIcon',
    route: 'review-documents',  
    resource: 'AdminCarbono',
    action: 'all',
  },
  {
    title: 'Productores',
    icon: 'UsersIcon',
    route: 'producers-management',  
    resource: 'AuditorCarbono',
    action: 'all',   
  },
  {
    title: 'Fincas',
    icon: 'ImageIcon',
    route: 'farms-management',  
    resource: 'AuditorCarbono',
    action: 'all',   
  },
  {
    title: 'Capturas y emisiones',
    icon: 'CloudSnowIcon',
    route: 'register-metrics',  
    resource: 'AuditorCarbono',
    action: 'all',   
  },
  {
    title: 'Ventas Créditos',
    icon: 'TrendingUpIcon',
    route: 'carbon-sales',  
    resource: 'AuditorCarbono',
    action: 'all',   
  },
  {
    title: 'Checklist',
    icon: 'CheckSquareIcon',
    route: 'farms-checklist',  
    resource: 'AuditorCarbono',
    action: 'all',   
  },
  {
    title: 'Lista Eventos',
    icon: 'CheckSquareIcon',
    route: 'events-list',  
    resource: 'AuditorCarbono',
    action: 'all',   
  },
  {
    title: 'Conf. Documentos',
    icon: 'CheckSquareIcon',
    route: 'calendar-documents-administration',  
    resource: 'AuditorCarbono',
    action: 'all',
  },
  {
    title: 'Gestión Documentos',
    icon: 'CheckSquareIcon',
    route: 'review-documents',  
    resource: 'AuditorCarbono',
    action: 'all',
  }
]
