<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <!-- <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div> -->

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown right toggle-class="d-flex align-items-center dropdown-user-link" class="dropdown-user">
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{userEmail}}
            </p>            
            <span v-if="userType == 'Inversionista'" class="user-status">NeoGanadero</span>
            <span v-if="userType == 'Productor'" class="user-status">Productor</span>
            <span v-if="userType == 'ProductorInversionista'" class="user-status">Productor NeoGanadero</span>
            <span v-if="userType == 'Admin'" class="user-status">Admin</span>
            <span v-if="userType == 'Operador'" class="user-status">Operador</span>
            <span v-if="userType == 'ProductorCarbono'" class="user-status">Productor Carbono</span>
            <span v-if="userType == 'AdminCarbono'" class="user-status">Admin Carbono</span>
            <span v-if="userType == 'AuditorCarbono'" class="user-status">Auditor Carbono</span>
          </div>
          <b-avatar size="40" variant="light-primary" badge :src="profilePictureUrl" class="badge-minimal" badge-variant="success"/>
        </template>
        
        <b-dropdown-item link-class="d-flex align-items-center" @click="goToLoginWithRedirection" v-if="userEmail==''">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Iniciar Sesión</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{name:'register'}" v-if="userEmail==''">
          <feather-icon
            size="16"
            icon="UserPlusIcon"
            class="mr-50"
          />
          <span>Crear Cuenta</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center" :to="{name:'edit-account'}" v-if="userEmail">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Cuenta</span>
        </b-dropdown-item>

        <!-- <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item> -->

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center"  @click="logout" v-if="userEmail">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userEmail: '',
      userType: '',
      profilePictureUrl: '',      
      projectCode: null
    }
  },
  created() {
    if(this.$route != null && this.$route.query != null && this.$route.query.projectCode != null) {
      this.projectCode = this.$route.query.projectCode;      
    } 

    var userData = getUserData();
    if(userData){      
      this.userEmail = userData.email;
      this.userType = userData.userType;
      this.profilePictureUrl = userData.profilePictureUrl;
    }
  },
  methods: {
    logout() {
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)

      // Remove userData from localStorage
      localStorage.removeItem('userData')

      // Redirect to login page
      this.$router.push({ name: 'login' })
    },
    goToLoginWithRedirection() {      
      console.log(this.projectCode)
      this.$router.replace({path: 'login?redirectToProjectCode=' + this.projectCode});
    },
  },
}
</script>
